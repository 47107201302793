import { GLOBAL_VAR } from 'src/app/models/global-variable';
export interface IMenuItem {
  icon?: string;
  icon_type?: 'img' | 'mat';
  title: string;
  route: any | any[];
  child: IChildItem[];
  svgIcon: string;
  permissions?: string[];
}

export interface IChildItem {
  icon?: string;
  icon_type?: 'img' | 'mat';
  title: string;
  route: any | any[];
  svgIcon: string;
  permissions?: string[];
}

export const MENU = [
  {
    title: 'dashboard',
    route: '/home',
    icon: '',
    svgIcon: '',
    child: [],
    permissions: []
  },
  {
    title: 'admin_service',
    route: '',
    icon: '',
    svgIcon: '',
    child: [],
    permissions: []
  },
  {
    title: 'policy',
    route: '',
    icon: '',
    svgIcon: '',
    child: [],
    permissions: []
  },
  {
    title: 'contact',
    route: '/contact',
    icon: '',
    svgIcon: '',
    child: [],
    permissions: []
  },
  {
    title: 'follow_request',
    route: '/system/all-request',
    icon: '',
    svgIcon: '',
    child: [],
    permissions: []
  }
];

export const SYSTEM_MENU = [
  {
    title: 'request_service',
    route: `/${GLOBAL_VAR.PRE_ROUTE_PATH}/request`,
    icon: '',
    svgIcon: 'request-service',
    child: [],
    permissions: []
  },
  {
    title: 'all_request',
    route: `/${GLOBAL_VAR.PRE_ROUTE_PATH}/all-request`,
    icon: '',
    svgIcon: 'all-request',
    child: [],
    permissions: []
  }
];
